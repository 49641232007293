import React, { forwardRef, useContext } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { IconButton } from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
//@ts-ignore
import styled from 'styled-components'
import { findZelisBatches } from '../../../actions/ZelisActions'
import ViewZelisBatchDetailOverlay from './ViewZelisBatchDetailOverlay'
import FileLinkBtn from '../../../components/FileLinkBtn/FileLinkBtn'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import dateTime from '../../../utils/dateTime'

interface zelisBatch {
  ID: number
  FileID: number
  CheckAmount: number
  EOPAmount: number
  Date: string | null
  FileName: string
  S3Key: string
  PaymentCount: number
  IsReprint: boolean
}

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-invoice-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`

export * from '../../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  ViewZelisDetails: {
    name: '',
    details: {
      dataFormat(_: any, row: zelisBatch): any {
        return <InlineViewZelisBatchDetail data={row} />
      },
    },
  },
  ID: {
    name: 'Batch ID',
    details: {
      sortName: 'zelis_batch.id',
      dataFormat(_: any, row: zelisBatch) {
        return (
          <span>
            {row.ID}
            {row.IsReprint && (
              <>
                &nbsp;&nbsp;&nbsp;
                <span style={{ color: 'red' }}>[Reprint]</span>
              </>
            )}
          </span>
        )
      },
    },
  },
  Date: {
    name: 'Date',
    details: {
      sortName: 'zelis_batch.date',
      dataFormat: dateTime.cellFormatter(),
    },
  },
  S3Key: {
    name: 'File',
    details: {
      dataFormat(_: any, row: zelisBatch): any {
        return (
          <FileLinkBtn
            filename={row.FileName}
            phi={true}
            handleGetDownloadURLFail={() =>
              console.error('Unable to download file')
            }
            fileId={row.FileID}
          />
        )
      },
    },
  },
  PaymentCount: { name: 'Payment Count' },
  CheckAmount: {
    name: 'Check Amount',
    details: {
      dataFormat(_: any, row: zelisBatch): string {
        return formatAmount(row.CheckAmount.toString())
      },
      sortName: 'zelis_batch.check_amount',
    },
  },
  EOPAmount: {
    name: 'EOP Amount',
    details: {
      dataFormat(_: any, row: zelisBatch): string {
        return formatAmount(row.EOPAmount.toString())
      },
      sortName: 'zelis_batch.eop_amount',
    },
  },
})

const defaultSort = { col: 'zelis_batch.id', dir: 'desc' }

export const Table = forwardRef(function Table(
  {
    customColumns = defaultColumns,
    apiEndpoint = findZelisBatches,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 250,
      DataTableProps: {
        keyProp: 'ID',
        ...DataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap className={`${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

function InlineViewZelisBatchDetail({
  data,
}: {
  data: any
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <ViewZelisBatchDetailOverlay
        ref={ref}
        batchID={data.ID}
        onClose={() => refresh()}
      />
    </>
  )
}

export function FilterBatchDate(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <div style={{ paddingBottom: '1rem' }}>
      <ManagedDateInput
        name="BatchDate"
        label="Batch Date"
        value={filter.BatchDate}
        propagateOnlyEmptyOrValid={true}
        setter={({ value }) => {
          setFilterImmediate({ batchDate: value })
        }}
      />
    </div>
  )
}
