import React, { forwardRef, useContext, useEffect, useState } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
//@ts-ignore
import styled from 'styled-components'
import {
  findZelisBatches,
  findZelisBatchPayments,
} from '../../../actions/ZelisActions'
import dateTime from '../../../utils/dateTime'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import {
  RenderSelectField2,
  SetterArgs,
} from '../../../components/Inputs/standard'

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-invoice-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`

export * from '../../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  ID: { details: { hidden: true } },
  PaymentID: {
    name: 'Payment ID',
    details: {
      dataFormat: (_: any, row: any) => row.PaymentID,
      sortName: `zelis_batch_payment.payment_id`,
    },
  },
  Date: {
    name: 'Batch Date',
    details: {
      dataFormat: dateTime.cellFormatter(),
      sortName: `zelis_batch_payment.batch_date`,
    },
  },
  BillingEntityName: {
    name: 'Billing Entity Name',
    details: {
      sortName: `zelis_batch_payment.billing_entity_name`,
    },
  },
  OrganizationName: {
    name: 'Organization Name',
    details: {
      sortName: `zelis_batch_payment.organization_name`,
    },
  },
  CheckAmount: {
    name: 'Check Amount',
    details: {
      dataFormat(_: any, row: zelisBatchPayment): React.ReactElement {
        return (
          <span>
            {formatAmount(row.CheckAmount.toString())}{' '}
            {row.PaymentIsVoid && <span style={{ color: 'red' }}>[VOID]</span>}
          </span>
        )
      },
      sortName: `zelis_batch_payment.check_amount`,
    },
  },
})

interface zelisBatchPayment {
  ID: number
  PaymentID: string
  Date: string
  BillingEntityName: string
  OrganizationName: string
  PaymentIsVoid: boolean
  PaymentAmount: number
  CheckNumber: number
  CheckAmount: number
  IsReprint: boolean
}

const defaultSort = { col: 'zelis_batch_payment.id', dir: 'desc' }

export const Table = forwardRef(function Table(
  {
    customColumns = defaultColumns,
    apiEndpoint = findZelisBatchPayments,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 25,
      DataTableProps: {
        keyProp: 'ID',
        ...DataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
        isRowCheckable: (row: zelisBatchPayment) => !row.PaymentIsVoid,
      },
    },
    ref
  )

  return (
    <StyledTableWrap className={`${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

export function FilterBatchDate(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <div style={{ paddingBottom: '1rem' }}>
      <ManagedDateInput
        name="ZelisBatchDate"
        label="Zelis Batch Date"
        value={filter.BatchDate}
        propagateOnlyEmptyOrValid={true}
        setter={({ value }) => {
          setFilterImmediate({ batchDate: value })
        }}
      />
    </div>
  )
}

export function FilterZelisBatch(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [batches, setBatches] = useState<any[]>([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!filter.batchDate) {
      setBatches([])
      setFilterImmediate({ ...filter, batchUUIDs: null })
      return
    }

    findZelisBatches({
      filter: { batchDate: filter.batchDate, isReprint: false },
    })
      .then((res: any) => {
        let batchOptions = res.Data.map((batch: any) => ({
          value: batch.UUID,
          label: `Batch ${batch.ID}`,
        }))

        setBatches(batchOptions)
      })
      .catch(catchAPIError({ defaultMessage: 'Failed to get Zelis Batches' }))
  }, [filter.batchDate])

  if (batches.length === 0) return <></>

  return (
    <RenderSelectField2
      name={'Zelis Batch'}
      label={'Zelis Batch'}
      value={filter.batchUUIDs?.[0]}
      setter={({ value }: SetterArgs) => {
        setFilterImmediate({ batchUUIDs: [value] })
      }}
      items={batches}
      FormControlProps={{
        margin: 'dense',
        size: 'medium',
        fullWidth: true,
      }}
    />
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
