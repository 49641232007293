import utils from '../utils'
import React from 'react'
import { Link } from 'react-router-dom'
import Employer from './Employer'
import { fileTypeLinksFormatter } from '../utils/ReactFormatters'
import { useConfig } from '../providers/Config'
import CopyIcon from '../components/CopyIcon'
import dateTime from '../utils/dateTime'

const READ_PERMISSION = 'r:invoice'
const CREATE_PERMISSION = 'c:invoice'

const columns = {
  KeyColumn: 'ID',
  ID: { name: 'ID', details: { hidden: true } },
  Number: { name: 'Invoice No.', details: {} },
  CopyTeamLink: {
    name: 'Copy Link',
    details: { dataFormat: copyTeamLinkFormatter },
  },
  DateOfIssue: {
    name: 'Issued',
    details: { dataFormat: dateTime.cellFormatter(), sortName: 'DateOfIssue' },
  },
  Files: { name: 'Files', details: { dataFormat: fileTypeLinksFormatter } },
  Status: {
    name: 'Status',
    details: {
      dataSort: true,
      sortFunc: statusSortFn,
      dataFormat: getStatusDescr,
    },
  },
  Amount: {
    name: 'Amount',
    details: {
      dataFormat: utils.deprecatedRowTableMoneyFormatter,
      sortName: 'InvoiceAmount',
    },
  },
  Type: { name: 'Type', details: { dataFormat: getTypeDescr } },
  ACHWeekday: {
    name: 'ACH',
    details: { dataFormat: dowFormatter, sortFunc: dowSorter, dataSort: true },
  },
  BillingMethod: {
    name: 'Method',
    details: {
      dataFormat: billingMethodFormatter,
      sortFunc: billingMethodSorter,
      dataSort: true,
    },
  },
  Notes: { name: 'Notes', details: {} },
}

const columnsWithEmployerName = Object.assign(
  {},
  {
    EmployerName: {
      name: 'Employer',
      details: {
        dataSort: true,
        dataFormat: employerLinkFormatter,
        hidden: false,
      },
    },
  },
  columns
)

const invoice = {
  columns: columns,
  columnsWithEmployerName,
  editFields: {
    StatusId: 1,
    DateOfIssue: null,
    SentDate: null,
    ApprovedDate: null,
    FundedDate: null,
    FarleyPaidDate: null,
    Notes: '',
  },
  hasAccess: () => {
    return utils.checkPerms(READ_PERMISSION)
  },
  canCreate: () => {
    return utils.checkPerms(CREATE_PERMISSION)
  },
  STATUS_SENT: 3,
  STATUS_APPROVED: 4,
  STATUS_PAID_FULL: 5,
  STATUS_PAID_FARLEY: 6,
  STATUS_DISPUTED: 7,
  INVOICE_TYPE_PEPM: 2,
}

function getTypeDescr(cell, row) {
  return cell.Descr
}

function getStatusDescr(cell, row) {
  return cell.Descr
}

function statusSortFn(a, b, order) {
  const aVal = a.Status.Descr
  const bVal = b.Status.Descr

  return basicSorter(aVal, bVal, order)
}

const dowSortVals = {
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
  sun: 7,
  notSet: 8,
}

function dowSorter(a, b, order) {
  const aVal = dowSortVals[a.ACHWeekday] || dowSortVals.notSet
  const bVal = dowSortVals[b.ACHWeekday] || dowSortVals.notSet

  return basicSorter(aVal, bVal, order)
}

function dowFormatter(cell, row) {
  const opts = Employer.achWeekdayOptions
  const val = opts.find((opt) => opt.value === cell)

  return val ? val.label : ''
}

function billingMethodFormatter(cell, row) {
  const opts = Employer.billingMethodOptions
  const val = opts.find((opt) => opt.value === cell)

  return val ? val.label : ''
}

function billingMethodSorter(a, b, order) {
  const aVal = a.BillingMethod
  const bVal = b.BillingMethod

  return basicSorter(aVal, bVal, order)
}

function basicSorter(aVal, bVal, order) {
  if (order === 'desc') {
    return aVal < bVal ? -1 : aVal > bVal ? 1 : 0
  }
  return bVal < aVal ? -1 : bVal > aVal ? 1 : 0
}

function employerLinkFormatter(cell, row) {
  return (
    <Link to={`/employer/${row.EmployerId}`} target="_blank" name="view-link">
      {row.Employer.Name}
    </Link>
  )
}

const CopyTeamLink = (props) => {
  const { teamzeroUrl } = useConfig()
  const { employerId, invoiceId } = props
  const link = `${teamzeroUrl}/employer/${employerId}/invoices?viewingID=${invoiceId}&viewing=true`
  return <CopyIcon value={link} />
}

function copyTeamLinkFormatter(_cell, row) {
  return <CopyTeamLink employerId={row.EmployerId} invoiceId={row.ID} />
}

export default invoice
