import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import PageInvoiceableView from './viewInvoiceable'
import PageInvoicedView from './viewInvoiced'
import PageReceipts from './viewReceipts'
import PagePayableView from './viewPayable'
import PagePaidView from './viewPaid'
import PageZelisView from './viewZelis'
import { useSetFrameHeaderMid } from '../../../components/AppFrame'
import useQueryParamsGen2 from '../../../hooks/useQueryParamsGen2'

const SUB_VIEW_INVOICEABLE = 'invoiceable'
const SUB_VIEW_INVOICED = 'invoiced'
const SUB_VIEW_RECEIPTS = 'receipts'
const SUB_VIEW_PAYABLE = 'payable'
const SUB_VIEW_PAID = 'paid'
const SUB_VIEW_ZELIS = 'zelis'

export default function PageFunding(): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.page_funding.subview',
  })
  const [subView, setSubView] = useState(
    queryData['subView'] || SUB_VIEW_INVOICEABLE
  )

  useEffect(() => {
    setQueryData({ subView })
  }, [subView])

  useSetFrameHeaderMid(() => {
    return (
      <>
        <Button
          size="small"
          variant={subView === SUB_VIEW_INVOICEABLE ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_INVOICEABLE)}>
          Invoiceable
        </Button>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Button
          size="small"
          variant={subView === SUB_VIEW_INVOICED ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_INVOICED)}>
          Invoiced
        </Button>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Button
          size="small"
          variant={subView === SUB_VIEW_RECEIPTS ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_RECEIPTS)}>
          Receipts
        </Button>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Button
          size="small"
          variant={subView === SUB_VIEW_PAYABLE ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_PAYABLE)}>
          Payable
        </Button>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Button
          size="small"
          variant={subView === SUB_VIEW_PAID ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_PAID)}>
          Paid
        </Button>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Button
          size="small"
          variant={subView === SUB_VIEW_ZELIS ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_ZELIS)}>
          Zelis
        </Button>
      </>
    )
  }, [subView])

  switch (subView) {
    case SUB_VIEW_INVOICEABLE:
      return <PageInvoiceableView />

    case SUB_VIEW_INVOICED:
      return <PageInvoicedView />

    case SUB_VIEW_RECEIPTS:
      return <PageReceipts />

    case SUB_VIEW_PAYABLE:
      return <PagePayableView />

    case SUB_VIEW_PAID:
      return <PagePaidView />

    case SUB_VIEW_ZELIS:
      return <PageZelisView />
  }

  return <span>Invalid state</span>
}
