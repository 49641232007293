import React, { useEffect, useState } from 'react'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import PageClaims from './pageClaims'
import PageFundingReview from './pageFundingReview'
import PageFunding from './pageFunding'
// import PageInvoicing from './pageInvoicing'
// import PageReceipts from './pageReceipts'
// import PagePayables from './pagePayables'
import PageSavingsReview from './pageSavingsReview'
import { useSetFrameHeaderLeft } from '../../components/AppFrame'
import { Chip } from '@material-ui/core'
//@ts-ignore
import styled from 'styled-components'

const StyledComponent = styled.div`
  height: 100%;
`

const StyledHeaderTabs = styled.div``

const TAB_CLAIMS = 'tab_claims'
const TAB_FUNDING_REVIEW = 'tab_pre_funding'
const TAB_FUNDING = 'tab_funding'
// const TAB_INVOICING = 'tab_invoicing'
// const TAB_RECEIPTS = 'tab_receipts'
// const TAB_PAYABLES = 'tab_payables'
const TAB_SAVINGS = 'tab_savings'

export default function ZClaims(/*props : any*/): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.tabs',
  })
  const [tabVal, setTabVal] = useState(queryData?.tabVal || TAB_CLAIMS)

  useEffect(() => {
    setQueryData({ tabVal })
  }, [tabVal])

  useSetFrameHeaderLeft(
    () => (
      <div>
        <StyledHeaderTabs>
          <Chip
            variant={tabVal === TAB_CLAIMS ? 'default' : 'outlined'}
            color="primary"
            label="Claims"
            onClick={() => setTabVal(TAB_CLAIMS)}
          />
          &nbsp;
          <Chip
            variant={tabVal === TAB_FUNDING_REVIEW ? 'default' : 'outlined'}
            color="primary"
            label="Pre-Funding"
            onClick={() => setTabVal(TAB_FUNDING_REVIEW)}
          />
          {/* &nbsp;
          <Chip
            variant={tabVal === TAB_INVOICING ? 'default' : 'outlined'}
            color="primary"
            label="Invoicing"
            onClick={() => setTabVal(TAB_INVOICING)}
          />
          &nbsp;
          <Chip
            variant={tabVal === TAB_RECEIPTS ? 'default' : 'outlined'}
            color="primary"
            label="Receipts"
            onClick={() => setTabVal(TAB_RECEIPTS)}
          />
          &nbsp;
          <Chip
            variant={tabVal === TAB_PAYABLES ? 'default' : 'outlined'}
            color="primary"
            label="Payables"
            onClick={() => setTabVal(TAB_PAYABLES)}
          /> */}
          &nbsp;
          <Chip
            variant={tabVal === TAB_FUNDING ? 'default' : 'outlined'}
            color="primary"
            label="Funding"
            onClick={() => setTabVal(TAB_FUNDING)}
          />
          <>
            &nbsp;
            <Chip
              variant={tabVal === TAB_SAVINGS ? 'default' : 'outlined'}
              color="primary"
              label="Savings"
              onClick={() => setTabVal(TAB_SAVINGS)}
            />
          </>
        </StyledHeaderTabs>
      </div>
    ),
    [tabVal, setTabVal]
  )

  return (
    <StyledComponent>
      {tabVal === TAB_CLAIMS && <PageClaims />}
      {tabVal === TAB_FUNDING_REVIEW && <PageFundingReview />}
      {tabVal === TAB_FUNDING && <PageFunding />}
      {tabVal === TAB_SAVINGS && <PageSavingsReview />}
    </StyledComponent>
  )
}
