import React, { useEffect, useState } from 'react'
import { useAuth } from '../../providers/Auth'
import AppFrameSidebar from './sidebar'
import styled from 'styled-components'
import { Typography, IconButton, useScrollTrigger } from '@material-ui/core'
import {
  ExitToApp as IconLogout,
  VerticalSplit as IconBorderLeft,
  AspectRatio as IconFullWidth,
} from '@material-ui/icons'
import DesignSuite2023 from '../DesignSuite2023'
import DialogCreateSnippet from '../../views/Schmipit/DialogCreateSnippet'
import { SnackMessage } from '../SnackMessage'
import * as tzcHelper from '../../services/thezerocard/helper'

interface AppFrameProps {
  content: any
  contentKey: string
  title: string
}

const StyledAppFrame = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  margin: 0 auto;

  &.auth-loading {
    .app-frame-content {
      text-align: center;
      padding: 2rem;
    }
  }

  .app-frame-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 200px;
    height: fit-content;
    min-height: 100vh;
    max-width: calc(100vw - 200px);
    z-index: 1;
    transition: all 0.15s ease-in-out;
    overflow: clip;
    overflow-clip-margin: 1rem;

    .app-frame-header {
      min-height: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      top: 0;
      z-index: 99;
      backdrop-filter: blur(5px);
      // width: 100%;
      border-bottom-left-radius: 8px;
      transition: background 0.15s ease-in-out;
      padding: 0 1rem 0 3rem;

      .app-frame-header-left {
        h1,
        .page-title {
          font-size: 1.5rem;
          margin: 0;
          padding: 0;
          line-height: 1;

          &.smaller {
            font-size: 1.2rem;
          }
        }
      }

      .app-frame-header-right {
        white-space: nowrap;
        min-width: fit-content;
      }
    }

    .app-frame-content {
      padding: 1rem;
      background: #f1f1f1;
      flex: 1;
      border-top-left-radius: 20px;
      overflow: visible;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      border: none; // 2px solid transparent;
      // height: calc(100vh - 60px);
    }
  }

  &.scrolled {
    .app-frame-header {
      background: rgba(0, 0, 0, 0.15);
    }
  }

  &.sidebar-closed {
    .app-frame-sidebar {
      opacity: 0;
    }
    .app-frame-container {
      margin-left: 0;
      max-width: 100%;
    }
  }

  &.frame-height-lock {
    .app-frame-container {
      height: 100vh;

      .app-frame-content {
        height: calc(100vh - 60px);
        padding: 0;
        position: relative;
        overflow: hidden;
      }
    }
  }

  &.full-width {
    .app-frame-container {
      max-width: none;
    }
  }

  #btn-layout-full-width {
    display: none;
  }

  @media (min-width: 2200px) {
    #btn-layout-full-width {
      display: inline-block;
    }
    .app-frame-container {
      .app-frame-header {
        padding-left: 5.15rem;
      }
    }

    &:not(.full-width) {
      max-width: 2200px;

      .app-frame-header {
        border-bottom-right-radius: 8px;
      }
      .app-frame-content {
        border-top-right-radius: 20px;
      }
    }
  }
`

const StyledToggleIconBtn = styled.span`
  display: inline-block;
  // opacity: 0.8;
  position: absolute;
  top: 50%;
  left: 0.5rem;
  z-index: 101;
  transform: translateY(-50%);

  .MuiIconButton-root {
    background: rgba(0, 0, 0, 0.1);
    padding: 0.4rem;
  }
`

interface shapeAppFrameContext {
  headerContentLeft?: React.ReactElement | null
  setHeaderContentLeft(re: React.ReactElement | null): void
  headerContentMid?: React.ReactElement | null
  setHeaderContentMid(re: React.ReactElement | null): void
  frameHeightLock?: boolean
  setFrameHeightLock(lock: boolean): void
}

const appFrameContext = React.createContext<shapeAppFrameContext>(
  {} as shapeAppFrameContext
)

/*
This is the actual component that gets exported
*/
export default function AppFrame(props: AppFrameProps): React.ReactElement {
  const [headerContentLeft, setHeaderContentLeft] =
    React.useState<React.ReactElement | null>(null)
  const [headerContentMid, setHeaderContentMid] =
    React.useState<React.ReactElement | null>(null)
  const [frameHeightLock, setFrameHeightLock] = React.useState(false)

  return (
    <appFrameContext.Provider
      value={{
        headerContentLeft,
        setHeaderContentLeft,
        headerContentMid,
        setHeaderContentMid,
        frameHeightLock,
        setFrameHeightLock,
      }}>
      <RenderAppFrame {...props} />
    </appFrameContext.Provider>
  )
}

/*
This is the primary component definition that has the context in scope
*/
function RenderAppFrame({
  content,
  title,
  // contentKey
}: AppFrameProps): React.ReactElement {
  const { headerContentLeft, headerContentMid, frameHeightLock } =
    React.useContext(appFrameContext)
  const { isAuthenticated, user, logout, loading: authLoading } = useAuth()
  const [sidebarMin, setSidebarMin] = useState(false)
  const [fullWidth, setFullWidth] = useState(false)
  const trigger = useScrollTrigger({
    target: window,
    threshold: 10,
    disableHysteresis: true,
  })
  const refFrame = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    refFrame?.current?.classList.toggle('scrolled', trigger)
  }, [trigger])

  useEffect(() => {
    refFrame?.current?.classList.toggle('frame-height-lock', frameHeightLock)
    refFrame?.current?.classList.toggle('sidebar-closed', sidebarMin)
    refFrame?.current?.classList.toggle('full-width', fullWidth)
  }, [frameHeightLock, sidebarMin, fullWidth])

  // do this once, upon mount
  useEffect(() => {
    document.body.classList.add(
      tzcHelper.isProduction() ? 'env-mode-prod' : 'env-mode-non-prod'
    ) //set('env-mode-non-prod', !tzcHelper.isProduction())
  }, [])

  function toggleSidebar() {
    setSidebarMin((curr: boolean) => !curr)
  }

  function toggleFullWidth() {
    setFullWidth((curr: boolean) => !curr)
  }

  const iconToggleSidebar = (
    <DesignSuite2023.Tooltip title="Toggle sidebar">
      <StyledToggleIconBtn style={{ left: '0.5rem' }}>
        <IconButton size="small" color="inherit" onClick={toggleSidebar}>
          <IconBorderLeft
            fontSize="inherit"
            color={sidebarMin ? 'secondary' : 'inherit'}
          />
        </IconButton>
      </StyledToggleIconBtn>
    </DesignSuite2023.Tooltip>
  )

  const iconToggleFullWidth = (
    <DesignSuite2023.Tooltip title="Toggle full width">
      <StyledToggleIconBtn
        id="btn-layout-full-width"
        style={{ left: '2.75rem' }}>
        <IconButton size="small" color="inherit" onClick={toggleFullWidth}>
          <IconFullWidth
            fontSize="inherit"
            color={fullWidth ? 'secondary' : 'inherit'}
          />
        </IconButton>
      </StyledToggleIconBtn>
    </DesignSuite2023.Tooltip>
  )

  return (
    <>
      <StyledAppFrame
        ref={refFrame}
        className={`${authLoading ? 'auth-loading' : ''}`}>
        <AppFrameSidebar />

        <div className="app-frame-container">
          <header className="app-frame-header">
            {iconToggleSidebar}
            {iconToggleFullWidth}

            <div className="app-frame-header-left">
              {(!!headerContentLeft && headerContentLeft) || (
                <h1 className="page-title">{title}</h1>
              )}
            </div>

            {!!headerContentMid && (
              <div className="app-frame-header-mid">{headerContentMid}</div>
            )}

            <div className="app-frame-header-right">
              <DialogCreateSnippet />
              &nbsp;|&nbsp;
              <DesignSuite2023.Tooltip title="Logout">
                <IconButton color="inherit" onClick={logout}>
                  <IconLogout fontSize="inherit" />
                </IconButton>
              </DesignSuite2023.Tooltip>
              <Typography component="span" style={{ fontSize: '0.9rem' }}>
                {user?.name}
              </Typography>
            </div>
          </header>

          <div className="app-frame-content">
            {authLoading ? (
              <DesignSuite2023.LoadingSpinner />
            ) : isAuthenticated ? (
              content
            ) : null}
          </div>
        </div>
      </StyledAppFrame>

      <SnackMessage />
    </>
  )
}

export function useSetFrameHeaderLeft(h: any, watches: Array<any> = []): void {
  const { setHeaderContentLeft } = React.useContext(appFrameContext)
  const comp: any = React.useMemo(h, watches)

  React.useLayoutEffect(() => {
    setHeaderContentLeft(comp)
    return () => setHeaderContentLeft(null)
  }, [comp])
}

export function useSetFrameHeaderMid(h: any, watches: Array<any> = []): void {
  const { setHeaderContentMid } = React.useContext(appFrameContext)
  const comp: any = React.useMemo(h, watches)

  React.useLayoutEffect(() => {
    setHeaderContentMid(comp)
    return () => setHeaderContentMid(null)
  }, [comp])
}

export function useFrameHeightLock(
  lock: () => boolean,
  watches: Array<any> = []
): void {
  const { setFrameHeightLock } = React.useContext(appFrameContext)

  React.useLayoutEffect(() => {
    setFrameHeightLock(lock())
    return () => setFrameHeightLock(false)
  }, watches)
}
