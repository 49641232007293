import React, { useState } from 'react'
import * as actions from '../../actions/SavingsAnalysisV2'
import { Button } from '@material-ui/core'

interface props {
  onDone?: () => void
  size?: 'small' | 'medium' | 'large'
}

export default function ButtonGenerateBundles({
  onDone,
  size = 'medium',
}: props): React.ReactElement {
  const [isWorking, setIsWorking] = useState(false)

  function doGenerateSavingsBundles() {
    setIsWorking(true)
    actions
      .postSavingsAnalysisGenerateBundles()
      .then((res) => {
        if (res.error) throw res
        onDone?.()
      })
      .catch(() => {})
      .finally(() => {
        setIsWorking(false)
      })
  }

  return (
    <Button
      size={size}
      disabled={isWorking}
      onClick={doGenerateSavingsBundles}
      color="primary"
      variant="contained">
      Generate Savings Bundles
    </Button>
  )
}
