import React, { useMemo } from 'react'
import DialogReferralSearch from '../../../Referrals/DialogReferralSearch'
import { Button } from '@material-ui/core'
import {
  getLinkedReferrals,
  linkReferralsToZClaim,
} from '../../../../actions/ZClaimActions'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import { Check } from '@material-ui/icons'

interface props {
  zClaimID: number | null
  employerMemberID: number | null
}

export default function LinkReferrals({
  zClaimID,
  employerMemberID,
}: props): React.ReactElement | null {
  const dialogRef = React.useRef<any>(null)
  const [selectedReferrals, setSelectedReferrals] = React.useState<any[]>([])
  const [linkedReferrals, setLinkedReferrals] = React.useState<any[]>([])

  const { catchAPIError } = useErrorHandlers()

  const linkedReferralIDs = useMemo(() => {
    return linkedReferrals.map((referral) => referral.ReferralID)
  }, [linkedReferrals])

  function loadLinkedReferrals() {
    if (!zClaimID) return
    getLinkedReferrals(zClaimID)
      .then((res: any) => {
        setLinkedReferrals(res.Data)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed loading linked referrals' })
      )
  }

  function doLink(referralIDs: number[]) {
    if (!zClaimID) return

    linkReferralsToZClaim(zClaimID, referralIDs)
      .then(() => {
        dialogRef.current?.close()
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed linking referrals to claim' })
      )
  }

  const customColumns = Object.freeze({
    Link: {
      name: 'Linked',
      details: {
        dataFormat(_: any, row: any): any {
          if (!linkedReferralIDs.includes(row.ID)) return null
          return (
            <DesignSuite2023.Tooltip title={`Referral is linked to this claim`}>
              <Check style={{ color: 'green' }} />
            </DesignSuite2023.Tooltip>
          )
        },
      },
    },
  })

  return (
    <>
      <DialogReferralSearch
        passFilters={
          employerMemberID ? { employerMemberIDs: [employerMemberID] } : {}
        }
        rightHeaderItems={
          <>
            {selectedReferrals.length > 0 && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => doLink(selectedReferrals.map((ref) => ref.ID))}>
                Link Referrals
              </Button>
            )}
          </>
        }
        passColumns={customColumns}
        passDataTableProps={{
          isRowCheckable: (row: any) => !linkedReferralIDs.includes(row.ID),
        }}
        fnOnCheckHandler={setSelectedReferrals}
        fnOnTrigger={loadLinkedReferrals}
        Trigger={(props: any) => (
          <Button {...props} variant="outlined" size="small" fullWidth>
            Link Referrals
          </Button>
        )}
        ref={dialogRef}
      />
    </>
  )
}
