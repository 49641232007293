import React, { useState } from 'react'
import useSnackbar from '../../../hooks/useSnackbar'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import * as api from '../../../services/thezerocard/api-helper'
import { SetterArgs } from '../../../components/Inputs/standard'

import DesignSuite2023 from '../../../components/DesignSuite2023'
import { OrganizationSearcherV2 } from '../../../components/Searchers'

type postArchiveReferralsParams = {
  throughDate: string | null
  orgID: number
}

function initialPostArchiveReferralsParams(): postArchiveReferralsParams {
  return {
    throughDate: null,
    orgID: 0,
  }
}

function postArchiveReferrals(params: postArchiveReferralsParams) {
  return api
    .post('/engineering/bulk_archive_org_referrals_through_date', params)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res
    })
}

export default React.forwardRef(
  function ButtonDoBulkArchiveReferrals(): React.ReactElement {
    const [isWorking, setIsWorking] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [postArchiveReferralsParams, setPostArchiveReferralsParams] =
      useState<postArchiveReferralsParams>(initialPostArchiveReferralsParams())
    const { showForDuration: showSnackbar } = useSnackbar()
    const { catchAPIError } = useErrorHandlers()
    const refOrgPicker = React.useRef<any>(null)

    function openDialog() {
      setIsOpen(true)
    }

    const setter = ({ name, value }: SetterArgs) => {
      setPostArchiveReferralsParams((curr: postArchiveReferralsParams) => ({
        ...curr,
        [name]: value,
      }))
    }

    const handleClose = () => {
      setPostArchiveReferralsParams(initialPostArchiveReferralsParams())
      setIsOpen(false)
    }

    function archiveReferrals() {
      setIsWorking(true)
      postArchiveReferrals(postArchiveReferralsParams)
        .then(() => {
          showSnackbar('Archive referrals completed OK', 'success')
          setPostArchiveReferralsParams(initialPostArchiveReferralsParams())
          setIsOpen(false)
        })
        .catch(catchAPIError({ defaultMessage: `Failed to archive referrals` }))
        .finally(() => {
          setIsWorking(false)
        })
    }

    return (
      <>
        <Button
          disabled={isWorking}
          variant="contained"
          color="primary"
          onClick={openDialog}>
          Bulk Archive Referrals
        </Button>
        <Dialog
          open={isOpen}
          onClose={() => {}} // no-op: only close on user close
          maxWidth="lg">
          <DialogTitle>
            Archive all referrals for an org through a date
          </DialogTitle>
          <DialogContent style={{ width: '1080px' }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <ManagedDateInput
                  name="throughDate"
                  label="Through Date"
                  value={postArchiveReferralsParams.throughDate}
                  setter={setter}
                />
              </Grid>
              <Grid item xs={9}>
                <OrganizationSearcherV2
                  ref={refOrgPicker}
                  selectedOrganizationID={postArchiveReferralsParams.orgID}
                  onChange={(id: number | null) => {
                    setter({ name: 'orgID', value: id })
                  }}
                  TextFieldProps={{
                    InputLabelProps: { shrink: true },
                  }}
                  disabled={isWorking}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <DesignSuite2023.GridLR
              left={
                <>
                  <Button
                    disabled={isWorking}
                    color="secondary"
                    variant="outlined"
                    size="small"
                    onClick={handleClose}>
                    Cancel
                  </Button>
                </>
              }
              right={
                <>
                  <Button
                    disabled={
                      isWorking ||
                      !postArchiveReferralsParams.throughDate ||
                      !postArchiveReferralsParams.orgID
                    }
                    color="primary"
                    variant="outlined"
                    onClick={archiveReferrals}>
                    Archive Referrals
                    {isWorking && (
                      <>
                        &nbsp;
                        <DesignSuite2023.LoadingSpinner size={20} show />
                      </>
                    )}
                  </Button>
                </>
              }
            />
          </DialogActions>
        </Dialog>
      </>
    )
  }
)
