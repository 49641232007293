import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Done from '@material-ui/icons/Done'
import _ from 'lodash'

import utils from '../../utils'
import { clearSaveResult, OpportunityAnalysisActions } from '../../actions'
import DetailView, {
  defaultStyleCreator,
} from '../../components/DetailView/DetailView'
import ChildTable from '../../components/ChildTable/ChildTable'
import Models from '../../models'
import ShowV2Rules from './ShowV2Rules'
import dateTime from '../../utils/dateTime'

import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@material-ui/core'

const {
  getSummary,
  getSummaryClaimLines,
  saveGroupLines,
  saveGroupSummary,
  getEmployerForFileClaims,
  clearSelectedSummary,
} = OpportunityAnalysisActions
const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)

  return Object.assign(defaults, {
    textField: {
      display: 'inline',
    },
    dataFields: {
      lineHeight: '1',
      paddingBottom: '0.35rem',
    },
    fieldLabel: {
      display: 'inline-block',
      fontWeight: 'bold',
      paddingRight: '5px',
      verticalAlign: 'middle',
      lineHeight: '1',
    },
    fieldValue: {
      display: 'inline-block',
      overflow: 'hidden',
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      verticalAlign: 'middle',
      lineHeight: '1',
      whiteSpace: 'nowrap',
    },
    tooltipWidth: {
      maxWidth: 300,
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
    saveBtn: {
      margin: '10px 5px',
    },
    field: {
      margin: '5px',
      width: 200,
    },
  })
}

export class SummaryDetail extends DetailView {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.getInitialState())
    this.state.originalFields = Object.assign({}, this.state.fields)
    this.tabs = {
      claimLines: 0,
    }
  }

  getInitialState = () => {
    return {
      loadingClaimLines: false,
      saving: false,
      tabValue: 0,
      serviceDateRange: 14,
      fields: {
        CostKey: '',
        ShowGroup: '',
        TotalZero: '',
        Notes: '',
        ShowAllForPatient: false,
      },
      selectedRows: [],
      linesInGroup: [],
      changedLines: {},
      changedFields: {},
    }
  }

  componentWillUnmount() {
    this.props.clearSelectedSummary()
    this.props.clearSaveResult()
  }

  componentDidMount() {
    if (this.props.summaryId) this.props.getSummary(this.props.summaryId)
    this.props.clearSaveResult()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.summaryId && nextProps.summaryId !== this.props.summaryId) {
      this.props.getSummary(nextProps.summaryId)
    }

    if (
      nextProps &&
      nextProps.summary &&
      nextProps.summary.ID &&
      (nextProps.summary.ID !== this.props.summary.ID ||
        nextProps.summary.ReviewDate !== this.props.summary.ReviewDate)
    ) {
      const newSummary = Object.assign({}, nextProps.summary)
      this.setState({
        ...this.getInitialState(),
        fields: newSummary,
        originalFields: newSummary,
      })
      this.props.getEmployerForFileClaims(newSummary.FileClaimsId)
      this.refreshClaimLines(nextProps.summary)
    }
  }

  refreshClaimLines = (summary) => {
    const { serviceDateRange, fields } = this.state

    let params = {
      file_claims_id: summary.FileClaimsId,
      patient_id: summary.PatientId,
    }
    if (!fields.ShowAllForPatient) {
      params = Object.assign({}, params, {
        service_start: dateTime
          .parse(summary.StartDate)
          .format(dateTime.formats.ISODate),
        service_date_range: serviceDateRange,
      })
    }

    this.setState({ loadingClaimLines: true })

    this.props.getSummaryClaimLines(params).then((res) => {
      let selected = []
      const lines = res.payload.Data || []
      if (lines && lines.length > 0) {
        selected = lines.reduce((acc, line) => {
          if (line.GroupNr === summary.GroupNr) {
            acc.push(line.ID)
          }
          return acc
        }, [])
      }
      this.setState({
        loadingClaimLines: false,
        selectedRows: selected,
        linesInGroup: Array.from(selected),
      })

      // refresh summary too
      this.props.getSummary(this.props.summaryId)
    })
  }

  handleSaveSummary = (showGroup) => {
    const { fields } = this.state

    const handler = async (e) => {
      const summary = Object.assign({}, this.props.summary, fields, {
        TotalZero: fields.TotalZero * 1,
      })

      summary.ShowGroup = showGroup * 1

      await this.props.saveGroupSummary(summary)
      this.props.getSummary(this.props.summaryId)
    }

    return handler
  }

  handleSaveLines = () => {
    const selectLineIds = this.state.selectedRows
    const { summaryClaimLines } = this.props
    const group = this.props.summary.GroupNr

    const linesForPatch = selectLineIds.map((s) => {
      const item = _.find(summaryClaimLines, (line) => line.ID === s)
      return { ID: s, GroupNr: group, IsIgnored: item.IsIgnored }
    })

    this.setState({
      saving: true,
    })

    this.props.saveGroupLines(linesForPatch).then((res) => {
      if (!res.error) {
        this.setState({ saving: false, changedLines: {} })
        this.refreshClaimLines(this.props.summary)
      }
    })
  }

  handleRowSelect = (row, isSelected, e) => {
    if (row && row !== null) {
      const { selectedRows } = this.state
      if (isSelected) {
        selectedRows.push(row.ID)
      } else {
        _.pull(selectedRows, row.ID)
      }

      this.setState({
        selectedRows: selectedRows,
      })
    }
  }

  renderChildTabs() {
    return (
      <div>
        <Tabs
          value={this.state.tabValue}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="inherit">
          <Tab value={this.tabs.claimLines} label="Claim Lines" />
        </Tabs>
        <div className={this.props.classes.childContainer}>
          {this.renderChildTabContent()}
        </div>
      </div>
    )
  }

  renderLocalStatusIcon = () => {
    let result
    const { classes } = this.props
    if (this.props.saveResult && this.props.saveResult.saved) {
      result = <Done className={classes.statusIcon} />
      this.saveResultTimer = setTimeout(() => {
        this.props.clearSaveResult()
      }, 3000)
    }

    return (
      <div className={this.props.classes.statusIconContainer}>{result}</div>
    )
  }

  onAfterEdit = (details) => {
    const { changedLines } = this.state
    // easy as only a boolean
    if (changedLines[details.ID]) {
      delete changedLines[details.ID]
    } else {
      changedLines[details.ID] = true
    }

    this.setState({ changedLines })
  }

  hasChangedLines() {
    const { changedLines } = this.state

    return Object.keys(changedLines).length > 0
  }

  handleShowAllForPatient = (e) => {
    this.setState(
      {
        fields: {
          ...this.state.fields,
          ShowAllForPatient: e.target.checked,
        },
      },
      () => {
        this.refreshClaimLines(this.props.summary)
      }
    )
  }

  renderChildTabContent() {
    const { selectedRows, linesInGroup } = this.state
    const selectRow = {
      mode: 'checkbox',
      selected: selectedRows,
      unselectable: linesInGroup,
      onSelect: this.handleRowSelect,
      bgColor: (row, isSelect) => {
        const { GroupNr } = this.state.fields

        if (row.GroupNr === GroupNr) {
          return '#5cb85c' // 'green'
        } else {
          return '#efd14d' // 'yellow'
        }
      },
    }

    const cellEdit = {
      nonEditableRows: () => {
        return this.props.summaryClaimLines
          .filter((l) => selectedRows.includes(l.ID) === false)
          .map((l) => l.ID)
      },
    }

    if (this.state.tabValue === this.tabs.claimLines) {
      return (
        <ChildTable
          columns={Models.ProspectClaimLines.customColumns(
            this.onAfterEdit,
            this.props.summary.GroupNr
          )}
          rows={this.props.summaryClaimLines}
          loading={this.state.loadingClaimLines}
          selectRow={selectRow}
          cellEdit={cellEdit}
          options={{ defaultSortName: 'ServiceStart', defaultSortOrder: 'asc' }}
          search
        />
      )
    }

    return <div />
  }

  render() {
    const { classes, summary } = this.props
    const savings = summary.TotalAllowed - summary.TotalZero
    const savingsPercentage = (savings / summary.TotalAllowed) * 100
    const saveSummaryIsDisabled =
      this.state.selectedRows.length !== this.state.linesInGroup.length ||
      this.hasChangedLines()

    return (
      <div>
        {this.props.summary && this.props.summary.ID !== undefined ? (
          <Paper className={classes.mainContent} elevation={2}>
            <Grid container spacing={2} wrap="wrap">
              <Grid item xs={12} sm={3}>
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Employer:</span>
                  <span className={classes.fieldValue}>
                    {summary.FileClaimsId &&
                      (this.props.employer.Name || (
                        <CircularProgress size={20} />
                      ))}
                  </span>
                </div>
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Service Date: </span>
                  <span className={classes.fieldValue}>
                    {dateTime
                      .parse(summary.StartDate)
                      .format(summary.StartDate)}
                  </span>
                </div>
                <br />
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Claim Nr: </span>
                  <Tooltip
                    title={summary.ClaimNr}
                    classes={{ tooltip: classes.tooltipWidth }}>
                    <span className={classes.fieldValue}>
                      {summary.ClaimNr}
                    </span>
                  </Tooltip>
                </div>
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Group Number: </span>
                  <span className={classes.fieldValue}>{summary.GroupNr}</span>
                </div>
                <br />
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Patient: </span>
                  <Tooltip
                    title={summary.PatientId}
                    classes={{ tooltip: classes.tooltipWidth }}>
                    <span className={classes.fieldValue}>
                      {summary.PatientId}
                    </span>
                  </Tooltip>
                </div>
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Patient Zip: </span>
                  <span className={classes.fieldValue}>
                    {summary.PatientZip}
                  </span>
                </div>
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Review Rule: </span>
                  <Tooltip
                    title={summary.ShowRule}
                    classes={{ tooltip: classes.tooltipWidth }}>
                    <span className={classes.fieldValue}>
                      {summary.ShowRule}
                    </span>
                  </Tooltip>
                </div>
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Rule: </span>
                  <Tooltip
                    title={summary.Rule}
                    classes={{ tooltip: classes.tooltipWidth }}>
                    <span className={classes.fieldValue}>{summary.Rule}</span>
                  </Tooltip>
                </div>
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>V2 Bundle Rules:</span>
                  <div>
                    <ShowV2Rules costKey={summary.CostKey} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Cost Key: </span>
                  <span className={classes.fieldValue}>{summary.CostKey}</span>
                </div>
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Cost Key Descr: </span>
                  <span className={classes.fieldValue}>
                    {summary.CostKeyCode && summary.CostKeyCode.LaymanDescr}
                  </span>
                </div>
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>
                    Manually Reviewed:{' '}
                  </span>
                  <span className={classes.fieldValue}>
                    {summary.IsManuallyChanged ? 'Yes' : 'No'}
                  </span>
                </div>
                <br />
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Allowed: </span>
                  <span className={classes.fieldValue}>
                    {utils.deprecatedRowTablePriceFormatter(
                      summary.TotalAllowed
                    )}
                  </span>
                </div>
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Zero Cost: </span>
                  <span className={classes.fieldValue}>
                    {utils.deprecatedRowTablePriceFormatter(summary.TotalZero)}{' '}
                    ({utils.deprecatedRowTablePriceFormatter(savings)} /{' '}
                    {savingsPercentage.toFixed(2)}%)
                  </span>
                </div>
                <br />
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Review State: </span>
                  <span className={classes.fieldValue}>
                    {summary.ShowGroup}
                  </span>
                </div>
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Default: </span>
                  <span className={classes.fieldValue}>
                    {summary.IsDefaultGroup ? 'Yes' : 'No'}
                  </span>
                </div>
                <div className={classes.dataFields}>
                  <span className={classes.fieldLabel}>Notes: </span>
                  <span className={classes.fieldValue}>{summary.Notes}</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                {this.renderTextField('CostKey', 'Cost Key')}
                {this.renderTextField('ShowGroup', 'Status')}
                <br />
                {this.renderTextField('TotalZero', 'Zero Cost')}
                {this.renderTextField('GroupPosCode', 'POS')}
                <br />
                {this.renderTextField('Notes', 'Notes', {
                  multiline: true,
                  rows: '4',
                  rowsMax: '8',
                })}
                <br />
                <Tooltip
                  title="Rejected"
                  classes={{ tooltip: classes.tooltipWidth }}>
                  <Button
                    variant="contained"
                    onClick={this.handleSaveSummary(0)}
                    disabled={saveSummaryIsDisabled}
                    className={classes.saveBtn}>
                    0 - Reject
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Accepted"
                  classes={{ tooltip: classes.tooltipWidth }}>
                  <Button
                    variant="contained"
                    onClick={this.handleSaveSummary(1)}
                    disabled={saveSummaryIsDisabled}
                    className={classes.saveBtn}>
                    1 - Accept
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Part of multiple procedures; could not extract clean bundle"
                  classes={{ tooltip: classes.tooltipWidth }}>
                  <Button
                    variant="contained"
                    onClick={this.handleSaveSummary(7)}
                    disabled={saveSummaryIsDisabled}
                    className={classes.saveBtn}>
                    7 - MultiProc
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Not analyzeable for other reasons"
                  classes={{ tooltip: classes.tooltipWidth }}>
                  <Button
                    variant="contained"
                    onClick={this.handleSaveSummary(8)}
                    disabled={saveSummaryIsDisabled}
                    className={classes.saveBtn}>
                    8 - Not Analyzeable
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Emergency Service not properly identified by POS"
                  classes={{ tooltip: classes.tooltipWidth }}>
                  <Button
                    variant="contained"
                    onClick={this.handleSaveSummary(11)}
                    disabled={saveSummaryIsDisabled}
                    className={classes.saveBtn}>
                    11 - Emergency Service
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Inpatient Exclusion"
                  classes={{ tooltip: classes.tooltipWidth }}>
                  <Button
                    variant="contained"
                    onClick={this.handleSaveSummary(12)}
                    disabled={saveSummaryIsDisabled}
                    className={classes.saveBtn}>
                    12 - Inpatient Exclusion
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Incomplete billing"
                  classes={{ tooltip: classes.tooltipWidth }}>
                  <Button
                    variant="contained"
                    onClick={this.handleSaveSummary(14)}
                    disabled={saveSummaryIsDisabled}
                    className={classes.saveBtn}>
                    14 - Incomplete Billing
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Ask Provider Success"
                  classes={{ tooltip: classes.tooltipWidth }}>
                  <Button
                    variant="contained"
                    onClick={this.handleSaveSummary(15)}
                    disabled={saveSummaryIsDisabled}
                    className={classes.saveBtn}>
                    15 - Ask Provider Success
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Parking"
                  classes={{ tooltip: classes.tooltipWidth }}>
                  <Button
                    variant="contained"
                    onClick={this.handleSaveSummary(16)}
                    disabled={saveSummaryIsDisabled}
                    className={classes.saveBtn}>
                    16 - Parking (On Hold?)
                  </Button>
                </Tooltip>
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={this.state.fields.ShowAllForPatient}
                      value="this doesnt matter"
                      onChange={this.handleShowAllForPatient}
                    />
                  }
                  label="Show all claims for patient"
                />
                <Button
                  variant="contained"
                  onClick={this.handleSaveLines}
                  disabled={this.hasChanges() || !saveSummaryIsDisabled}
                  className={classes.saveBtn}>
                  Save Lines
                </Button>
                <Button
                  variant="contained"
                  onClick={this.handleSaveSummary(this.state.fields.ShowGroup)}
                  disabled={saveSummaryIsDisabled}
                  className={classes.saveBtn}>
                  Save Group Changes
                </Button>
                {this.renderLocalStatusIcon()}
              </Grid>
              <Grid item sm={12} lg={7}>
                {this.props.saveResult && this.props.saveResult.error && (
                  <Typography type="title" className={classes.errorMsg}>
                    {this.props.saveResult.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.sectionDivider} />
              </Grid>
              <Grid item xs={12}>
                {this.props.getError
                  ? this.renderError(this.props.getError.message)
                  : this.renderChildTabs()}
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <div />
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    employer: state.opportunityAnalysis.employer || {},
    summary: state.opportunityAnalysis.summary || {},
    summaryClaimLines: state.opportunityAnalysis.summaryClaimLines || [],
    saveResult: state.opportunityAnalysis.saveResult || {},
    getError: state.opportunityAnalysis.getError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSummary,
      getSummaryClaimLines,
      saveGroupLines,
      saveGroupSummary,
      getEmployerForFileClaims,
      clearSaveResult,
      clearSelectedSummary,
    },
    dispatch
  )
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SummaryDetail)
