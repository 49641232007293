import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import * as actions from '../../actions/SavingsAnalysisV2'
import styled from 'styled-components'
import StringifyCostKey from '../CostKeys/Components/StringifyCostKey'
import DesignSuite2023 from '../../components/DesignSuite2023'
import dateTime from '../../utils/dateTime'
import * as helpers from './helpers'
import utils from '../../utils'
import {
  Button,
  Checkbox,
  IconButton,
  Mark,
  Slider,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ShapeSavingsBundleLine } from './types'
import BtnChooseCostKey from './BtnChooseCostKey'
import ViewClaimDetailOverlay from '../ZClaims/Components/ViewClaimDetailOverlay'
import StatusDot from '../ZClaims/Components/StatusDot'
import ReasonCodes, { ShapeReasonCode } from './ReasonCodes'
import Status from './Status'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import DialogSavingsBundleNotes from '../ZClaims/Components/DialogSavingsBundleNotes'
import BtnExtractLinesNewBundle from './BtnExtractLinesNewBundle'
import { RenderPriceField } from '../../components/Inputs/standard'
import { Done } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'

const StyledBundleDetail = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  .sa-bundle-detail-overview {
    .overview-container {
      display: flex;
      // grid-column-gap: 2rem;
      width: fit-content;
      background: #ececec;
      border-radius: 8px;
      margin: 0 auto;
      overflow: hidden;

      aside {
        border-left: 2px solid #d3d3d3;
        padding: 1rem;

        > strong {
          display: block;
          margin: -1rem -1rem 1rem;
          background: #ccc;
          padding: 0.5rem 1rem;
        }

        &:first-of-type {
          border-left: none;
        }
      }
    }

    math {
      font-size: 90%;

      mn,
      mtext {
        font-family: monospace;
      }
    }
  }

  .sa-lines-view {
    background: #f9f9f9;
    // flex: 1;
    display: grid;
    grid-template-columns: repeat(12, min-content) 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-column-gap: 1.5rem;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    // width: 100%;
    // height: 100%;
    // overflow: hidden;
    // overflow-y: auto;
    max-height: 100%;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;

    header,
    .sa-line,
    .pinnable {
      display: grid;
      grid-template-columns: subgrid;
      grid-column: 1 / -1;
      border-bottom: 1px solid #e1e1e1;
    }

    .pinnable {
      background: #fff;

      &.nearby {
        overflow: hidden;
        overflow-y: auto;
      }
    }

    header,
    .sa-line {
      padding: 0.5rem 0.75rem;
    }

    header {
      background: #e9e9e9;
      position: sticky;
      top: 0;

      strong {
        position: relative;
        font-weight: 500;

        &:not(:empty)::before {
          content: '';
          width: 3px;
          background: #ddd;
          height: calc(100% + 1rem + 1px);
          position: absolute;
          left: calc(-0.75rem - 2px);
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .sa-line {
      font-family: monospace;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background: #f2f2f2;
      }

      &.is-ignored {
        // opacity: 0.4;
        text-decoration: line-through;
        background: #f6f0b3;
        color: #a79627;
      }
    }

    aside {
      grid-column: 1 / -1;
      padding: 0.5rem 0.75rem;
      background: #3f3f3f;
      color: #fff;
      display: flex;
      align-items: center;
      column-gap: 1rem;
      // justify-content: center;
      font-size: 86%;
      line-height: 1;
      z-index: 99;

      .MuiTypography-root {
        line-height: 1;
        font-size: inherit;
      }

      .MuiButton-root {
        font-size: inherit;

        &.Mui-disabled {
          color: inherit;
          border: 1px solid;
          opacity: 0.35;
        }
      }
    }
  }

  .trigger-debug {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: #aaa;
    font-family: monospace;
    cursor: pointer;
  }
`

interface props {
  savingsBundleUUID?: string
}

const nearbyLineDayRangeMarks = Object.freeze([
  { value: 1 },
  { value: 7 },
  { value: 15 },
  { value: 30 },
  { value: 45 },
  { value: 60 },
  { value: 90 },
  { value: 120 },
]) as Mark[]

const StyledSlider = withStyles({
  root: {
    color: '#73b382',
    height: 4,
    width: 200,
  },
  track: {
    height: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
    opacity: 0.5,
  },
  thumb: {
    height: 10,
    width: 10,
    marginTop: -3,
    marginLeft: -4,
    backgroundColor: '#6acd81',
    '&:focus, &:hover, &$active': {
      boxShadow: '0px 0px 0px 8px #6acd814a',
    },
  },
  mark: {
    width: 4,
    height: 18,
    marginTop: -7,
    borderRadius: 4,
    backgroundColor: '#aaa',
  },
  markActive: {
    backgroundColor: 'currentColor',
  },
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: 0,
    '& *': {
      background: 'transparent',
      color: '#fff',
    },
  },
})(Slider)

export default forwardRef(function BundleDetail(
  { savingsBundleUUID }: props,
  ref: any
): React.ReactElement | null {
  const [showDebug, setShowDebug] = useState<boolean>(false)
  const [data, setData] = useState<any>(null)
  const [checkedBundleLines, setCheckedBundleLines] = useState<
    ShapeSavingsBundleLine[]
  >([])
  const [checkedNearbyLines, setCheckedNearbyLines] = useState<any[]>([])
  const [uncommittedNearbyLinesDayRange, setUncommittedNearbyLinesDayRange] =
    useState<number>(30)
  const [filters, setFilters] = useState<any>({
    nearbyLinesDayRange: uncommittedNearbyLinesDayRange,
  })
  const [manualPPO, setManualPPO] = useState<string | null>(null)

  const { showDurationShort } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const computedProviders = useMemo((): string[] => {
    if (!(data?.ZClaims?.length >= 1)) return []

    let providers = new Set<string>(
      data.ZClaims.map((zc: any) => zc.OrganizationInfo?.Name)
    )
    return [...providers].sort((a, b) => a.localeCompare(b))
  }, [data])

  useEffect(() => {
    setCheckedBundleLines([])
    setCheckedNearbyLines([])
  }, [savingsBundleUUID])

  useEffect(() => {
    loadBundle()
  }, [filters, savingsBundleUUID])

  useEffect(() => {
    setManualPPO(data?.Bundle?.TypicalPPOManual)
  }, [data])

  function loadBundle() {
    if (!savingsBundleUUID) return
    actions
      .getSavingsAnalysisBundleByUUID(savingsBundleUUID, { filters })
      .then((res) => {
        if (res.error) throw res
        setCheckedBundleLines([])
        setCheckedNearbyLines([])
        setData(res.Data)
      })
      .catch(() => {})
      .finally(() => {})
  }

  function doIgnoreLines() {
    if (!savingsBundleUUID || !checkedBundleLines?.length) return
    const zClaimLineIDs = checkedBundleLines.map(
      (l) => l.ZClaimLineID
    ) as number[]
    actions
      .putSAIgnoreLines({ bundleUUID: savingsBundleUUID, zClaimLineIDs })
      .then((res) => {
        if (res.error) throw res
        return loadBundle()
      })
      .catch(() => {})
      .finally(() => {})
  }

  function doMergeIntoCurrentBundle() {
    if (!savingsBundleUUID || !checkedNearbyLines?.length) return
    const zClaimLineIDs = checkedNearbyLines.map((l) => l.ID) as number[]
    actions
      .putSABundleLineAction({
        zClaimLineIDs,
        ActionMerge: {
          ToSavingsBundleUUID: savingsBundleUUID,
        },
      })
      .then((res) => {
        if (res.error) throw res
        return loadBundle()
      })
      .catch(() => {})
      .finally(() => {})
  }

  function onBundleLineToggleSelected(ev: any, l: any) {
    ev.stopPropagation()

    const checked = ev.target.checked
    setCheckedBundleLines((prev) => {
      if (checked) return [...prev, l]
      return prev.filter((bl) => bl.ID !== l.ID)
    })
  }

  function onNearbyLineToggleSelected(ev: any, l: any) {
    ev.stopPropagation()

    const checked = ev.target.checked
    setCheckedNearbyLines((prev) => {
      if (checked) return [...prev, l]
      return prev.filter((bl) => bl.ID !== l.ID)
    })
  }

  function onClearReasonCode(rc: ShapeReasonCode) {
    actions
      .putSABundleReasonCode({
        bundleUUID: savingsBundleUUID!,
        clearReasonCodeHandles: [rc.ReasonCodeHandle!],
      })
      .then((res) => {
        if (res.error) throw res
        // @todo: report success w/ snackbar
        return loadBundle()
      })
  }

  function onAssignBundleCostKey(costKey: any) {
    if (!savingsBundleUUID || !costKey.ID) return

    actions
      .putSABundleCostKeyID(savingsBundleUUID, costKey.ID)
      .then((res) => {
        if (res.error) throw res
        showDurationShort('Cost Key assigned', SnackbarTypeSuccess)
        return loadBundle()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to update cost key',
        })
      )
  }

  function onAssignReasonCode(rc: ShapeReasonCode) {
    actions
      .putSABundleReasonCode({
        bundleUUID: savingsBundleUUID!,
        addReasonCodeHandles: [rc.Handle],
      })
      .then((res) => {
        if (res.error) throw res
        // @todo: report success w/ snackbar
        return loadBundle()
      })
  }

  function onManualPPOOverride(value: string | null) {
    if (!savingsBundleUUID) return

    actions
      .putSAManualPPO(savingsBundleUUID, value)
      .then((res) => {
        if (res.error) throw res
        showDurationShort('Manual PPO updated', SnackbarTypeSuccess)
        return loadBundle()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to update manual PPO',
        })
      )
  }

  if (!data?.Bundle.ID) return null

  return (
    <StyledBundleDetail className="sa-bundle-detail">
      <div className="sa-bundle-detail-overview">
        <div className="overview-container">
          <aside>
            <strong>Cost Key</strong>
            {!data.Bundle?.IsRX ? (
              <div style={{ display: 'flex', columnGap: '0.5rem' }}>
                <div>
                  <StringifyCostKey
                    highlighters
                    bd={data.CostKey?.BundleDefinition}
                    showV2Prefix={false}
                  />
                </div>
                <div>
                  <BtnChooseCostKey onChange={onAssignBundleCostKey} />
                </div>
              </div>
            ) : (
              `RX`
            )}
          </aside>
          <aside>
            <strong>Status</strong>
            <div>
              <Status status={data.Bundle.Status} />
            </div>
          </aside>
          <aside>
            <strong>Flags</strong>
            <div>
              <helpers.RenderBool value={data.Bundle.IsLOA} />{' '}
              <strong>Loa</strong>
            </div>
            <div>
              <helpers.RenderBool value={data.Bundle.IsTravel} />{' '}
              <strong>Travel</strong>
            </div>
            <div>
              <helpers.RenderBool
                value={data.Bundle.IsSynthesizedSavingsBundle}
              />{' '}
              <strong> Synthetic </strong>
            </div>
          </aside>

          <aside>
            <strong>Provider(s)</strong>
            <div>
              {computedProviders.map((p) => (
                <div key={p}>{p}</div>
              ))}
            </div>
          </aside>
          <aside>
            <strong>Manual PPO</strong>
            <div style={{ display: 'flex' }}>
              <RenderPriceField
                name="ManualPPO"
                label=""
                value={manualPPO || ''}
                setter={({ value }: any) => {
                  setManualPPO(value)
                }}
                opts={{ margin: 'none' }}
                use2023Styles
              />
              {!!manualPPO?.length &&
                Number(manualPPO) !== Number(data.Bundle?.TypicalPPOManual) && (
                  <IconButton
                    size="small"
                    onClick={() => onManualPPOOverride(manualPPO)}>
                    <Done />
                  </IconButton>
                )}
              {!!data.Bundle?.TypicalPPOManual && (
                <IconButton
                  size="small"
                  onClick={() => onManualPPOOverride(null)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </aside>
          <aside>
            <strong>Pricing</strong>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {/* @ts-ignore */}
              <math
                xmlns="http://www.w3.org/1998/Math/MathML"
                style={{
                  display: 'block',
                  whiteSpace: 'nowrap',
                }}>
                {/* @ts-ignore */}
                <mtable
                  columnalign="right left right left right left right left right left right left"
                  rowspacing="3pt"
                  columnspacing="0em 2em 0em 2em 0em 2em 0em 2em 0em 2em 0em"
                  displaystyle="true">
                  {/* @ts-ignore */}
                  <mtr>
                    {/* @ts-ignore */}
                    <mtd>
                      {/* @ts-ignore */}
                      <mn>
                        {utils.V2FormatAsCurrency(
                          data.Bundle.TypicalPPOManual ?? data.Bundle.TypicalPPO
                        ) || '?'}
                        {/* @ts-ignore */}
                      </mn>
                      {/* @ts-ignore */}
                    </mtd>
                    {/* @ts-ignore */}
                    <mtext>
                      {data.Bundle?.TypicalPPOManual ? '[manual ppo]' : '[ppo]'}
                      {/* @ts-ignore */}
                    </mtext>
                    {/* @ts-ignore */}
                  </mtr>
                  {/* @ts-ignore */}
                  <mtr>
                    {/* @ts-ignore */}
                    <mtd>
                      {/* @ts-ignore */}
                      <munder>
                        {/* @ts-ignore */}
                        <mrow>
                          {/* @ts-ignore */}
                          <mo>&#x2212;</mo>
                          {/* @ts-ignore */}
                          <mn>
                            {utils.V2FormatAsCurrency(data.Bundle?.PaidAmount)}
                            {/* @ts-ignore */}
                          </mn>
                          {/* @ts-ignore */}
                        </mrow>
                        {/* @ts-ignore */}
                        <mo>&#x005F;</mo>
                        {/* @ts-ignore */}
                      </munder>
                      {/* @ts-ignore */}
                    </mtd>
                    {/* @ts-ignore */}
                    <mtext>[paid]</mtext>
                    {/* @ts-ignore */}
                  </mtr>
                  {/* @ts-ignore */}
                  <mtr>
                    {/* @ts-ignore */}
                    <mtd>
                      {/* @ts-ignore */}
                      <mn>
                        {utils.V2FormatAsCurrency(data.Computed.CalcdSavings)}
                        {/* @ts-ignore */}
                      </mn>
                      {/* @ts-ignore */}
                    </mtd>
                    {/* @ts-ignore */}
                    <mtext
                      style={{
                        fontWeight: 'bold',
                        color:
                          data.Computed.CalcdSavingsPct > 0
                            ? StatusDot.Colors.GREEN
                            : StatusDot.Colors.RED,
                      }}>
                      [{data.Computed.CalcdSavingsPct}% Savings]
                      {/* @ts-ignore */}
                    </mtext>
                    {/* @ts-ignore */}
                  </mtr>
                  {/* @ts-ignore */}
                </mtable>
                {/* @ts-ignore */}
              </math>
            </div>
          </aside>
          <aside>
            <strong>Reason Codes</strong>
            <div>
              <ReasonCodes
                list={data.ReasonCodes}
                handleClearReasonCode={onClearReasonCode}
                onReasonCodeAssigned={onAssignReasonCode}
              />
            </div>
          </aside>
          <aside>
            <strong>Notes</strong>
            <>
              <DialogSavingsBundleNotes
                bundleUUID={data.Bundle.UUID}
                currentNotes={data.Bundle.Notes}
                onClose={() => loadBundle()}
              />
            </>
            <div>{data.Bundle.Notes}</div>
          </aside>
        </div>
      </div>

      <div style={{ flex: 1, overflow: 'hidden' }}>
        <div className="sa-lines-view">
          <header>
            <strong></strong>
            <strong>ZClaimID</strong>
            <strong>ServiceStart</strong>
            <strong>PaymentDate</strong>
            <strong>Billed ($)</strong>
            <strong>Paid ($)</strong>
            <strong>TypicalPPO ($)</strong>
            <strong>ProcedureCode</strong>
            <strong>Modifiers</strong>
            <strong>UBCode</strong>
            <strong>Units</strong>
            <strong>POSCode</strong>
            <strong>Provider</strong>
          </header>

          <div className="pinnable">
            <aside>
              <Typography variant="subtitle2" component={'div'}>
                Bundle Lines
              </Typography>
              <DesignSuite2023.Tooltip title="Modify the bundle by ignoring currently selected lines">
                <span>
                  <Button
                    onClick={doIgnoreLines}
                    disabled={
                      !checkedBundleLines?.length ||
                      !!checkedNearbyLines?.length
                    }
                    size="small"
                    variant="outlined"
                    color="inherit">
                    Ignore Lines
                  </Button>
                </span>
              </DesignSuite2023.Tooltip>
              {(!!checkedBundleLines?.length ||
                !!checkedNearbyLines?.length) && (
                <span>
                  <BtnExtractLinesNewBundle
                    lines={[...checkedBundleLines, ...checkedNearbyLines]}
                    onLinesExtracted={loadBundle}
                  />
                </span>
              )}
            </aside>

            {(data.BundleLines || []).map((l: any) => (
              <div
                key={`bl-${l.ID}-${l.ZClaimLineID}`}
                className={`sa-line ${l.IsIgnored ? 'is-ignored' : ''}`}>
                <div>
                  <Checkbox
                    onChange={(ev: any) => onBundleLineToggleSelected(ev, l)}
                    disabled={l.IsIgnored}
                    defaultValue={l.ID}
                    checked={
                      !!checkedBundleLines.find((sbl) => sbl.ID === l.ID)
                    }
                    size="small"
                    disableRipple
                    style={{ padding: 0 }}
                  />
                </div>
                <div>
                  <ViewClaimDetailOverlay
                    readOnly
                    zClaimID={l.ZClaimID}
                    Trigger={({ doOpen }: any) => (
                      <span
                        onClick={doOpen}
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}>
                        {l.ZClaimID}
                      </span>
                    )}
                  />
                </div>
                <div>
                  {dateTime.parse(l.ZClaimLine?.ServiceDateStart).format()}
                </div>
                <div>{dateTime.parse(l.PaymentDate).format()}</div>
                <div>{utils.V2FormatAsCurrency(l.ZClaimLine?.AmntBilled)}</div>
                <div>{utils.V2FormatAsCurrency(l.PaidAmount)}</div>
                <div style={{ color: '#888' }}>
                  {utils.V2FormatAsCurrency(l.Decorated?.TypicalPPOPrice) ||
                    '?'}
                </div>
                <div>{l.ZClaimLine?.ProcedureCode}</div>
                <div>
                  {joinArr(
                    l.ZClaimLine?.ModifierCode1,
                    l.ZClaimLine?.ModifierCode2,
                    l.ZClaimLine?.ModifierCode3,
                    l.ZClaimLine?.ModifierCode4
                  )}
                </div>
                <div>{l.ZClaimLine?.UBRevenueCode}</div>
                <div>{l.ZClaimLine?.Units}</div>
                <div>{l.ZClaimLine?.POSCode}</div>
                <div>{l.OrganizationName}</div>
              </div>
            ))}
          </div>

          <div className="pinnable nearby">
            <aside style={{ position: 'sticky', top: 0 }}>
              <Typography variant="subtitle2" component={'div'}>
                Nearby Lines (Across All Claims For Patient)
              </Typography>
              <div>
                <Button
                  disabled={
                    !checkedNearbyLines?.length || !!checkedBundleLines?.length
                  }
                  size="small"
                  variant="outlined"
                  color="inherit"
                  onClick={doMergeIntoCurrentBundle}>
                  Merge Into Current Bundle
                </Button>
              </div>
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  columnGap: '0.5rem',
                  paddingRight: '0.5rem',
                }}>
                <StyledSlider
                  value={uncommittedNearbyLinesDayRange}
                  marks={nearbyLineDayRangeMarks}
                  step={null}
                  valueLabelFormat={(v) => `${v}`}
                  min={1}
                  max={120}
                  valueLabelDisplay="off"
                  onChange={(e, v: any) => {
                    setUncommittedNearbyLinesDayRange(v)
                  }}
                  onChangeCommitted={(e, v: any) => {
                    setFilters((prev: any) => ({
                      ...prev,
                      nearbyLinesDayRange: parseInt(v),
                    }))
                  }}
                />
                <span>+/- {uncommittedNearbyLinesDayRange} Days</span>
              </div>
            </aside>

            {(data.NearbyLines || []).map((l: any) => (
              <div key={`zl-${l.ID}`} className="sa-line">
                <div>
                  <Checkbox
                    onChange={(ev: any) => onNearbyLineToggleSelected(ev, l)}
                    defaultValue={l.ID}
                    checked={
                      !!checkedNearbyLines.find((nbl) => nbl.ID === l.ID)
                    }
                    size="small"
                    disableRipple
                    style={{ padding: 0 }}
                  />
                </div>
                <div>
                  <ViewClaimDetailOverlay
                    readOnly
                    zClaimID={l.ZClaimID}
                    Trigger={({ doOpen }: any) => (
                      <span
                        onClick={doOpen}
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}>
                        {l.ZClaimID}
                      </span>
                    )}
                  />
                </div>
                <div>{dateTime.parse(l.ServiceDateStart).format()}</div>
                <div>{dateTime.parse(l.PaymentDate).format()}</div>
                <div>{utils.V2FormatAsCurrency(l.AmntBilled)}</div>
                <div>{utils.V2FormatAsCurrency(l.AmntPayable)}</div>
                <div style={{ color: '#888' }}>
                  {utils.V2FormatAsCurrency(l.Decorated?.TypicalPPOPrice) ||
                    '?'}
                </div>
                <div>{l.ProcedureCode}</div>
                <div>
                  {joinArr(
                    l.ModifierCode1,
                    l.ModifierCode2,
                    l.ModifierCode3,
                    l.ModifierCode4
                  )}
                </div>
                <div>{l.UBRevenueCode}</div>
                <div>{l.Units}</div>
                <div>{l.POSCode}</div>
                <div>{l.OrganizationName}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <small
        className="trigger-debug"
        onClick={() => setShowDebug((curr) => !curr)}>
        Debug
      </small>
      {showDebug && (
        <DesignSuite2023.StyledPre
          style={{
            position: 'absolute',
            left: '1rem',
            right: '1rem',
            bottom: '1rem',
            height: '50%',
            boxShadow: '0 0 20px rgba(0,0,0,0.8)',
            background: '#222222e6',
          }}>
          {JSON.stringify(
            { data, checkedBundleLines, checkedNearbyLines },
            null,
            2
          )}
        </DesignSuite2023.StyledPre>
      )}
    </StyledBundleDetail>
  )
})

function joinArr(...items: string[]) {
  return items.filter((v) => !!v).join(' | ')
}
