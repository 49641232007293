import React, { forwardRef, useContext, useState } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import {
  findReceipts,
  receiptType,
} from '../../../actions/ZClaimFundingActions'
import { EmployerSearcher } from '../../../components/Searchers'
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import ViewReceiptDetailOverlay from './ViewReceiptDetailOverlay'
//@ts-ignore
import styled from 'styled-components'
import dateTime from '../../../utils/dateTime'

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-invoice-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`
export * from '../../../hooks/useStandardTableSetup'

export enum PayerType {
  All = 'all',
  Employer = 'employer',
  BillingEntity = 'billing_entity',
}

export const defaultColumns = Object.freeze({
  ViewReceiptDetails: {
    name: '',
    details: {
      dataFormat(_: any, row: receiptTableRecord): any {
        return <InlineViewReceiptDetail data={row} />
      },
    },
  },
  ReceiptID: { name: 'Receipt ID', details: { sortName: 'ReceiptID' } },

  ReceiptDate: {
    name: 'Receipt Date',
    details: { sortName: 'ReceiptDate', dataFormat: dateTime.cellFormatter() },
  },
  PayerType: { name: 'Type', details: { dataFormat: getPayerTypeDisplay } },
  PayerName: {
    name: 'Name',
    details: {
      sortName: 'EmployerLegalName',
      dataFormat(_: any, row: receiptTableRecord): React.ReactElement {
        if (row.PayerType === PayerType.BillingEntity) {
          return <div>{row.PayerName}</div>
        }
        return (
          <div>
            <div>{row.EmployerLegalName}</div>
            {row.EmployerLegalName !== row.EmployerQuickbooksHandle && (
              <div
                style={{
                  fontSize: '0.875em',
                  fontStyle: 'italic',
                  color: 'secondary',
                }}>
                {row.EmployerQuickbooksHandle}
              </div>
            )}
            {row.EmployerLegalName !== row.EmployerName && (
              <div
                style={{
                  fontSize: '0.875em',
                  fontStyle: 'italic',
                  color: 'secondary',
                }}>
                {row.EmployerName}
              </div>
            )}
          </div>
        )
      },
    },
  },
  ReceiptType: {
    name: 'Method',
    details: {
      dataFormat(_: any, row: receiptTableRecord): string {
        if (row.ReceiptType === receiptType.CHECK) {
          return `Check ${row.CheckNumber}`
        } else if (row.ReceiptType === receiptType.ACH) {
          return 'ACH'
        } else {
          return row.ReceiptType
        }
      },
    },
  },
  ReceiptAmount: {
    name: 'Receipt',
    details: {
      dataFormat(_: any, row: receiptTableRecord): string {
        return formatAmount(row.ReceiptAmount)
      },
    },
  },
  AppliedAmount: {
    name: 'Applied',
    details: {
      dataFormat(_: any, row: receiptTableRecord): any {
        if (row.IsReversed) {
          return (
            <Typography variant="caption" style={{ color: 'red' }}>
              {`voided ${dateTime.parse(row.ReversalDate).format(dateTime.formats.AmericanDate, '')}`}
            </Typography>
          )
        }
        return formatAmount(row.AppliedAmount)
      },
    },
  },
  UnappliedAmount: {
    name: 'Balance',
    details: {
      dataFormat(_: any, row: receiptTableRecord): string {
        return row.IsReversed ? '' : formatAmount(row.UnappliedAmount)
      },
    },
  },
})

const defaultSort = { col: 'ReceiptID', dir: 'desc' }

function getPayerTypeDisplay(t: PayerType): string {
  switch (t) {
    case PayerType.All:
      return 'all'
    case PayerType.Employer:
      return 'employer'
    case PayerType.BillingEntity:
      return 'billing entity'
    default:
      return 'Unknown'
  }
}

export interface receiptTableRecord {
  ReceiptID: number
  ReceiptDate: string | null
  PayerName: string
  PayerType: PayerType
  EmployerName: string
  EmployerLegalName: string
  EmployerQuickbooksHandle: string
  ReceiptType: receiptType
  CheckNumber: string
  Notes: string
  ReceiptAmount: string
  AppliedAmount: string
  UnappliedAmount: string
  IsReversed: boolean
  ReversalDate: string | null
}

export const Table = forwardRef(function Table(
  {
    customColumns = defaultColumns,
    apiEndpoint = findReceipts,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      passFilters: { includeFullyApplied: true },
      initPageSize: 250,
      DataTableProps: {
        keyProp: 'ReceiptID',
        ...DataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`zclaims-invoice-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

function InlineViewReceiptDetail({
  data,
}: {
  data: receiptTableRecord
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <ViewReceiptDetailOverlay
        ref={ref}
        data={data}
        onClose={() => refresh()}
      />
    </>
  )
}

export function FilterIncludeZeroBalance(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <FormControlLabel
      label="Include $0 Balance"
      control={
        <Switch
          color="primary"
          checked={filter.includeFullyApplied}
          onChange={(e: any) => {
            if (e.target.checked) {
              setFilterImmediate({ ...filter, includeFullyApplied: true })
              return
            }
            setFilterImmediate({ ...filter, includeFullyApplied: null })
          }}
        />
      }
    />
  )
}

export function FilterEmployer(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <EmployerSearcher
      {...props}
      selectedEmployerID={filter.employerIDs?.[0]}
      onChange={(empID: number | null) => {
        if (!empID) {
          setFilterImmediate({ employerIDs: [] })
          return
        }
        setFilterImmediate({ employerIDs: [empID] })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 350 },
      }}
    />
  )
}

export function FilterPayerType(): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState(() => {
    return Object.values(PayerType).map((value: PayerType) => ({
      value,
      label: getPayerTypeDisplay(value),
    }))
  })

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ payerTypes: null })
    }
    setFilterImmediate({ payerTypes: [sel.props.value] })
  }

  const v = filter.payerTypes || PayerType.All

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Payer Type
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Payer Type"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
